<template>
  <div class="public-footer">
    <div class="touch-box">
      <img
        class="box-img"
        @click="message"
        src="../../assets/images/lianxi.png"
        alt=""
        title="联系我们"
      />
    </div>
    <div class="pf-companyDetail">
      <!-- 左边 -->
      <div class="pc-left">
        <div class="pl-nav">
          <router-link
            :to="{
              path: item.route,
              query: { titleId: item.titleId },
            }"
            v-for="(item, index) in navTitle"
            :key="index"
            >{{ item.name }}</router-link
          >
        </div>
        <div>
          <div v-if="lang == 'CN'">
            <p><span class="pf-title cn-title"> 联系电话</span> ：+86 29 62568966</p>
            <p><span class="pf-title cn-title"> E-mail</span> : info@claybbt.com</p>
            <p>
              <span class="pf-title cn-title"> 微信/手机</span> : claybbt / 13992888713
            </p>
            <p>
              <span class="pf-title cn-title"> 地址</span> :
              西安市高新区丈八一路绿地蓝海大厦10511室
            </p>
          </div>
          <div v-else>
            <p><span class="pf-title">Tel</span> : +86 29 62568966</p>
            <p><span class="pf-title">E-mail</span> : info@claybbt.com</p>
            <p>
              <span class="pf-title">WeChat/Mobile</span> : claybbt /+86 156 8605 1136
            </p>
            <p>
              <span class="pf-title">Address</span> : Room 0511, Lvdi Lanhai Building,
              Xi’An High Technology Zone, Xi’An, China
            </p>
          </div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="pc-right">
        <img class="ewm" src="../../assets/images/weixin.jpg" alt="" title="联系我们" />
      </div>
    </div>
    <div class="copyright">
      <div v-if="lang == 'CN'">
        西安博垦特建材科技有限公司 Copyright ©2021
        <a href="http://beian.miit.gov.cn" style="color: #6d6e6f"
          >陕ICP备2021006183号-1</a
        >
      </div>
      <div v-else>Copyright ©2021 XI’AN BBT CLAY TECHNOLOGIES CO.,LTD .</div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      navTitle: [],
      lang: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryOneTypebyCHorEN();
  },
  methods: {
    queryOneTypebyCHorEN() {
      let lang = localStorage.getItem("lang") || "CN";
      this.lang = lang;
      axios
        .get("http://edu.siwonet.com:8099/queryTitleByLanguage", {
          params: {
            language: lang,
            titleType: 1,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.navTitle = res.data.data;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    // 转跳页面
    message() {
      let titleId = localStorage.getItem("contact");
      this.$router.push({
        path: "/contact/contactUs",
        query: { titleId},
      });
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.public-footer {
  background: #313335;
  width: 100%;
  .touch-box {
    position: fixed;
    top: 700px;
    left: 1600px;
    .box-img {
      width: 100px;
      height: 100px;
    }
  }
  .pf-companyDetail {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    // 左边
    .pc-left {
      .pl-nav {
        display: flex;
        margin-bottom: 20px;
        a {
          color: rgb(255, 255, 255);
          font-size: 15px;
          margin-right: 23px;
        }
      }
      p {
        color: #9c9ca2;
        line-height: 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }
    .pf-title {
      display: block;
      width: 120px;
      text-align: left;
    }
    .cn-title {
      width: 80px !important;
    }
    // 右边
    .pc-right {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }

  // 底部备案号
  .copyright {
    color: #6d6e6f;
    font-size: 12px;
    // height: 50px;
    line-height: 50px;
    text-align: center;
    color: #6d6e6f;
    border-top: 1px solid #404143;
  }
}
</style>
