import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nodeId:{
      //key:titleId,value:nodeId
    }
  },
  mutations: {
    SET_NODE_ID(state,{titleId,nodeId}){
      Vue.set(state.nodeId,titleId,nodeId)
    }
  },
  actions: {
    setNodeId(context,option = {}){
      context.commit('SET_NODE_ID',option);
    }
  },
  modules: {}
});
