import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [{
    //首页
    path: "/",
    redirect: "/home",
    name: "Layout",
    component: Layout,
    children: [{
            path: "/home",
            name: "home",
            component: () =>
                import ("@/pages/home"),
        },
        // 关于我们
        {
            path: "/about",
            name: "about",
            redirect: "/about/companyProfile",
            component: () =>
                import ("@/pages/about"),
            children: [{
                    //公司概况
                    path: "/about/companyProfile",
                    name: "companyProfile",
                    component: () =>
                        import ("@/pages/about/common/companyProfile.vue"),
                },
                {
                    //公司文化
                    path: "/about/companyCulture",
                    name: "companyCulture",
                    component: () =>
                        import ("@/pages/about/common/companyCulture.vue"),
                },
                {
                    //团队介绍
                    path: "/about/technicalTeam",
                    name: "technicalTeam",
                    component: () =>
                        import ("@/pages/about/common/technicalTeam.vue"),
                },
                {
                    //资质荣誉
                    path: "/about/qualificationsHonors",
                    name: "qualificationsHonors",
                    component: () =>
                        import ("@/pages/about/common/qualificationsHonors.vue"),
                },
                {
                    //公司发展
                    path: "/about/developmentPath",
                    name: "developmentPath",
                    component: () =>
                        import ("@/pages/about/common/developmentPath.vue"),
                },
            ],
        },
        // 产品
        {
            path: "/prodect",
            name: "prodect",
            redirect: "/prodect/equipment",
            component: () =>
                import ("@/pages/prodect"),
            children: [{
                    //设备
                    path: "/prodect/equipment",
                    name: "equipment",
                    component: () =>
                        import ("@/pages/prodect/common/equipment.vue"),
                },
                {
                    //整体解决方案
                    path: "/prodect/overallSolution",
                    name: "overallSolution",
                    component: () =>
                        import ("@/pages/prodect/common/overallSolution.vue"),
                },
                {
                    //故事
                    path: "/prodect/story",
                    name: "story",
                    component: () =>
                        import ("@/pages/prodect/common/story.vue"),
                },
            ],
        },
        // 项目展示
        {
            path: "/demo",
            name: "demo",
            redirect: "/demo/projectDemo",
            component: () =>
                import ("@/pages/demo"),
            children: [{
                    //项目案例
                    path: "/demo/projectDemo",
                    name: "projectDemo",
                    component: () =>
                        import ("@/pages/demo/common/projectDemo.vue"),
                },
                {
                    //项目案例详情
                    path: "/demo/projectDemo/:id",
                    // path: "/demo/demoDetail",
                    name: "demoDetail",
                    component: () =>
                        import ("@/pages/demo/common/demoDetail.vue"),
                },
            ],
        },
        // 视频
        {
            path: "/video",
            name: "video",
            redirect: "/video/companyVideo",
            component: () =>
                import ("@/pages/video"),
            children: [{
                    //企业视频
                    path: "/video/companyVideo",
                    name: "companyVideo",
                    component: () =>
                        import ("@/pages/video/common/companyVideo.vue"),
                },
                {
                    //砖厂视频
                    path: "/video/brickFactoryVideo",
                    name: "brickFactoryVideo",
                    component: () =>
                        import ("@/pages/video/common/brickFactoryVideo.vue"),
                },
            ],
        },
        // 新闻
        {
            path: "/news",
            name: "news",
            redirect: "/news/companyNews",
            component: () =>
                import ("@/pages/news"),
            children: [{
                    //公司新闻
                    path: "/news/companyNews",
                    name: "companyNews",
                    component: () =>
                        import ("@/pages/news/common/companyNews.vue"),
                },
                {
                    //行内动态
                    path: "/news/industryTrends",
                    name: "industryTrends",
                    component: () =>
                        import ("@/pages/news/common/industryTrends.vue"),
                },
                {
                    //详情
                    // path: "/news/newsDetail",
                    path: "/news/companyNews/:id",
                    name: "newsDetail",
                    component: () =>
                        import ("@/pages/news/common/newsDetail.vue"),
                },
                {
                    //详情
                    path: "/news/industryTrends/:id",
                    name: "newsDetailIT",
                    component: () =>
                        import ("@/pages/news/common/newsDetail.vue"),
                },
            ],
        },
        // 联系我们
        {
            path: "/contact",
            name: "contact",
            redirect: "/contact/contactUs",
            component: () =>
                import ("@/pages/contact"),
            children: [{
                //联系我们
                path: "/contact/contactUs",
                name: "contactUs",
                component: () =>
                    import ("@/pages/contact/common/contactUs.vue"),
            }, ],
        },
        // 人力资源
        {
            path: "/recruit",
            name: "recruit",
            redirect: "/recruit/conceptEmployment",
            component: () =>
                import ("@/pages/recruit"),
            children: [{
                    //用人理念
                    path: "/recruit/conceptEmployment",
                    name: "conceptEmployment",
                    component: () =>
                        import ("@/pages/recruit/common/conceptEmployment.vue"),
                },
                {
                    //招聘
                    path: "/recruit/recruitment",
                    name: "recruitment",
                    component: () =>
                        import ("@/pages/recruit/common/recruitment.vue"),
                },
                {
                    //故事
                    path: "/recruit/story",
                    name: "recruitment",
                    component: () =>
                        import ("@/pages/recruit/common/story.vue"),
                },
            ],
        },
    ],
}, ];

const router = new VueRouter({

    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;