var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"public-header"},[(_vm.type != 'right')?_c('div',{staticClass:"nav-inside nav-inside-Mobile-none"},[_c('div',{staticClass:"nav-header",class:{ activeHeader: _vm.isShow }},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/images/公司logo.png"),"alt":""}}),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/images/展开更多.png"),"alt":""},on:{"click":_vm.openHeader}})]),_c('div',{staticClass:"nav-right",class:{ activeRight: _vm.isShow }},[_c('div',{staticClass:"nav-box"},[_c('img',{staticClass:"close",attrs:{"src":require("../../assets/images/错号.png"),"alt":""},on:{"click":_vm.closeHeader}}),_c('div',_vm._l((_vm.navTitle),function(item,index){return _c('router-link',{key:index,staticClass:"nav-name",attrs:{"to":{
              path: item.route,
              query: { titleId: item.titleId },
            }},nativeOn:{"click":function($event){return _vm.closeHeader($event)}}},[_vm._v(_vm._s(item.name))])}),1)])])]):_c('div',{staticClass:"nav-home"},[_c('div',_vm._l((_vm.navTitle),function(item,index){return _c('router-link',{key:index,staticClass:"nav-name",attrs:{"to":{
          path: item.route,
          query: { titleId: item.titleId },
        }}},[_vm._v(_vm._s(item.name))])}),1)]),_c('div',{staticClass:"nav-inside nav-inside-Mobile"},[_c('div',{staticClass:"nav-header",class:{ activeHeader: _vm.isShow }},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/images/公司logo.png"),"alt":""}}),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/images/展开更多.png"),"alt":""},on:{"click":_vm.openHeader}})]),_c('div',{staticClass:"nav-right",class:{ activeRight: _vm.isShow }},[_c('div',{staticClass:"nav-box"},[_c('img',{staticClass:"close",attrs:{"src":require("../../assets/images/错号.png"),"alt":""},on:{"click":_vm.closeHeader}}),_c('div',_vm._l((_vm.navTitle),function(item,index){return _c('router-link',{key:index,staticClass:"nav-name",attrs:{"to":{
              path: item.route,
              query: { titleId: item.titleId },
            }},nativeOn:{"click":function($event){return _vm.closeHeader($event)}}},[_vm._v(_vm._s(item.name))])}),1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }