<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  methods: {
    initLang() {
      const lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", "CN");
      }
    },
  },
  created() {
    this.initLang();
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  // .nav-inside .nav-right .nav-box .close {
  //   right: 15px !important;
  //   top: -35px !important;
  //   width: 40px !important;
  // }

  // 首页
  .nav-home {
    display: none !important;
    a {
      border: none !important;
      width: 100% !important;
    }
  }
  .nav-right {
    width: 100px !important;
    a {
      width: 100% !important;
    }
  }
  // 显示移动端用的导航栏----隐藏PC端
  .nav-inside-Mobile {
    display: block !important;
    .close {
      right: 20px !important;
      top: -15px !important;
      width: 30px !important;
    }
  }
  .nav-inside-Mobile-none {
    display: none !important;
  }
  // .nav-home a {
  //   border: none !important;
  // }
  // .nav-home a,
  // .nav-right a {
  //   width: 100% !important;
  // }
  .main-body .main-content .select-language {
    width: 130px !important;
    margin-left: 100px;
    margin-top: 55px !important;
  }
  .main-body .main-content .language-wrap {
    left: 0% !important;
    // display: none !important;
  }
  // 隐藏pc的切换语言
  .main-body .main-content .change-language {
    // display: none !important;
    left: 90px !important;
    // left: 0px !important;
    top: 5px !important;
    .cl-text {
      padding: 0px 20px !important;
      font-size: 16px !important;
    }
    img {
      display: none !important;
    }
  }
  // BTT
  .el-image,
  .el-image__inner {
    width: 100% !important;
  }
  .pi-box-img {
    max-width: 298px !important;
  }
  .companyProfile .cp-text {
    font-size: 12px !important;
  }

  .companyCulture .cc-content {
    flex-direction: column;
    .cc-pic {
      margin: 10px 10px !important;
    }
  }
  .technicalTeam .tt-introduce {
    // height: 650px !important;
    flex-direction: column;
    .tt-img {
      min-width: 100% !important;
      // min-height: 100% !important;
      // margin-top: 340px;
      flex-direction: column;
      align-items: center;
    }
    .ti-text {
      margin-left: 10px !important;
      margin-top: 15px;
    }
  }
  #n_dsj img {
    top: -50px !important;
    left: -20px !important;
    width: 170px !important;
  }
  #n_dsj .dd .pic_box {
    padding-right: 20px !important;
  }
  #n_dsj .dd {
    min-height: 120px !important;
    .pic_box img {
      height: 100px !important;
      margin-right: 20% !important;
    }
  }
  #n_dsj .dd .pic_box .pic {
    height: auto !important;
    border: none !important;
  }

  .gz_btn img {
    display: none;
  }
  #n_dsj .dd .text_box {
    margin-left: 180px !important;
    padding-top: 0px !important;
    width: 140px !important;
    .ar_article p {
      width: 100% !important;
    }
  }
  #n_dsj dd:nth-child(2n) {
    .text_box * {
      margin-left: 5px !important;
    }
    .pic_box img {
      margin-left: 20px !important;
    }
    .text_box {
      margin-left: -15px !important;
    }
  }
  //工艺
  .main-children__body .detail-content img {
    width: 100%;
  }
  // 产品
  .equipment {
    width: 100% !important;
    display: inline !important;
    .equipment-nav {
      width: 100% !important;
      .eq-tit {
        display: flex;
        justify-content: center;
      }
    }
  }
  .el-carousel__indicators {
    display: none;
  }
  // .equipment .equipment-nav {
  //   width: 100% !important;
  // }
  // .equipment .equipment-nav .eq-tit {
  //   display: flex;
  //   justify-content: center;
  // }
  .equipment-introduce .el-carousel__container {
    height: 220px !important;
    min-width: 315px !important;
  }
  .equipment-carouselBox,
  .os-carouselBox {
    width: 100% !important;
    height: 220px !important;
  }

  .equipment .equipment-introduce .downLoad {
    padding-bottom: 0px !important;
  }
  .industryTrends .industryTrends-content,
  .companyNews .companyNews-content {
    margin-left: -20px;
  }
  .projectDemo .projectDemo-introduce {
    margin-left: 10px;
  }
  // 项目
  .detail-content {
    width: 100% !important;
  }
  .projectDemo .projectDemo-introduce .pi-box {
    left: 28px !important;
    .pi-text {
      width: 100% !important;
      text-align: center;
    }
  }
  // 资讯 new
  .companyNews .companyNews-content .pi-box {
    padding: 190px 0px 15px 45px !important;
    .pi-createTime {
      margin-top: 10px !important;
    }
  }
  .industryTrends .industryTrends-content .pi-box {
    padding: 180px 30px 15px 45px !important;
    .pi-createTime {
      margin-top: 10px !important;
    }
  }
  .companyVideo {
    padding: 10px 0px !important;
  }
  .brickFactoryVideo {
    padding: 10px 0 !important;
  }
  .brickFactoryVideo
    .brickFactoryVideo-content
    .brickFactoryVideo-introduce
    .bv-box {
    display: inline !important;
  }
  .brickFactoryVideo
    .brickFactoryVideo-content
    .brickFactoryVideo-introduce
    .bv-box
    .bv-mainbody {
    width: 100% !important;
    padding: 25px 0 !important;
    .bv-content {
      height: 120px !important;
      width: 100% !important;
    }
    .bv-text {
      width: 100% !important;
    }
  }
  .edu-viedo {
    width: 100%;
    height: 220px;
  }

  //联系
  .equipment .equipment-introduce {
    flex-direction: column;
    height: 100% !important;
    .ei-box {
      flex-direction: column;
      .ei-word {
        width: auto !important;
      }
    }
  }
  // .equipment .equipment-introduce .ei-box {
  //   flex-direction: column;
  // }
  // .equipment .equipment-introduce .ei-box .ei-word {
  //   width: auto !important;
  // }
  .equipment .equipment-introduce .ei-word .qcode {
    justify-content: space-around !important;
    padding-right: 25px;
    img {
      width: 6.25rem !important;
      height: 6.25rem !important;
    }
  }
  // .equipment .equipment-introduce .ei-word .qcode img {
  //   width: 6.25rem !important;
  //   height: 6.25rem !important;
  // }

  .em-content .el-input-group {
    width: 300px !important;
  }
  .equipment .equipment-introduce .ei-msg {
    width: 100% !important;
    flex-direction: column !important;
  }
  .industryTrends .industryTrends-content .pi-box .pi-img {
    top: 10px !important;
    left: 45px !important;
  }
  .companyNews .companyNews-content .pi-box .pi-img {
    top: 10px !important;
    left: 45px !important;
  }
  .equipment .equipment-introduce .ei-msg .tips {
    margin-bottom: 10px !important;
  }
  .equipment .equipment-introduce .ei-msg .em-content {
    // display: block !important;
    flex-direction: column;
    align-items: center;
  }
  // .equipment .equipment-introduce .ei-msg .em-leaveWord {
  // margin: 0px 10px 0px 10px !important;
  // }
  // 人力资源
  .main-children__body {
    width: 100% !important;
    padding: 15px 30px !important;
  }
  .pf-companyDetail {
    width: 100% !important;
  }
  .public-footer .pf-companyDetail .pc-right img {
    display: none;
  }
  .nav-inside .nav-header .logo {
    width: 100px !important;
    height: 50px;
    margin-left: -90px;
  }
  .more {
    width: 40px !important;
    height: 20px !important;
    margin-right: -90px;
  }
  .banner-body .banner-content .four-stage {
    display: inherit !important;
    min-height: 60px !important;
    .fs-title {
      width: 100% !important;
      // 修改标签导航栏
      margin-right: 0px !important;
      border-radius: 0px !important;
      .fs-title-chinese {
        font-size: 13px !important;
        // line-height: 30px !important;
      }
    }
  }
  // .fs-title-chinese {
  //   font-size: 13px !important;
  // }
  .conceptEmployment .ce-theme {
    font-size: 16px !important;
    padding-top: 5px;
    margin-bottom: 20px !important;
  }

  .equipment .equipment-introduce .ei-word .ei-theme {
    font-size: 21px !important;
    margin-bottom: 20px !important;
  }
  // 招聘
  .equipment .equipment-introduce .card {
    margin: 0% !important;
    width: 100% !important;
    height: 120px !important;
    i {
      margin-bottom: 5px !important;
      margin-top: 0px !important;
    }
  }

  .equipment .equipment-introduce .ei-word {
    padding: 20px 0px 40px 15px !important;
    width: 100% !important;
  }
  .main-body .main-content .banner {
    .banner-textPic {
      left: 10% !important;
      font-size: 12px !important;
      width: 80% !important;
    }
    .logo {
      width: 60% !important;
      left: 18% !important;
    }
  }
  .conceptEmployment .conceptEmployment-introduce .ce-word {
    padding: 20px !important ;
  }
  // 底部
  // .public-footer .copyright {
  //   line-height: 50px !important;
  // }
  .pf-companyDetail {
    justify-content: center !important;
    padding: 0px !important;
    .pc-left {
      p {
        display: none !important;
      }
      .pl-nav {
        margin: 0px !important;
        display: none !important;
      }
    }
  }
  // .public-footer .pf-companyDetail .pc-left .pl-nav {
  //   margin: 0px !important;
  //   display: none !important;
  // }
  // .public-footer .pf-companyDetail .pc-left p {
  //   display: none !important;
  // }
  // .pswp__img,
  // .pswp__item {
  //   width: 785px !important;
  //   left: -265px !important;
  //   top: -630px !important;
  // }
  // .pswp__img,
  // .pswp__item {
  //   width: 375px !important;
  //   top: -180px !important;
  // }
  // .pre-img {
  //   width: 100% !important;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 1000;
  //   background-color: rgba(0, 0, 0, 0.85);
  //   overflow: hidden;
  // }
  // .pre-img img {
  //   width: 100% !important;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   right: 0px;
  //   bottom: 0;
  //   z-index: 1001;
  //   margin: auto;
  // }

  // 图片放大
  .pswp__item {
    width: 400px;
  }
  .pswp {
    width: 100% !important;
    box-sizing: border-box !important;
    .pswp__zoom-wrap {
      transform: translate3d(0px, 200px, 0px) scale(1) !important;
      .pswp__img {
        width: 100% !important;
        height: auto !important;
        left: 50% !important;
        // top: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
