<template>
  <div>
    <!-- 修改 -->
    <public-header :type="headerType" />
    <public-body />
    <public-footer v-if="isShowFooter" />
  </div>
</template>
<script>
import publicBody from "./public-body";
import publicFooter from "./public-footer";
import publicHeader from "./public-header";
export default {
  components: {
    publicBody,
    publicFooter,
    publicHeader,
  },
  computed: {
    headerType() {
      if (this.isHome) {
        return "right";
      }
      return undefined;
    },
    isShowFooter() {
      return this.isHome === false;
    },
    isHome() {
      return this.$route.name === "home";
    },
  },
};
</script>